import { Injectable } from '@angular/core';
import { Workspace } from '../interfaces/common/workspace';
import { HttpService } from '../backend/common/api/http.service';
import { Observable } from 'rxjs';
import { SimpleDateRange } from '../interfaces/common/date-range';

export const enum ForecastingMethod {
  PROPHET = 'PROPHET',
  STL = 'STL',
  DT = 'DT',
  RF = 'RF',
  XGB = 'XGB',
  ADA = 'ADA',
  ETR = 'ETR',
  Naive = 'Naive',
  NaiveSeasonal = 'NaiveSeasonal',
  LightGBM_RT = 'LightGBM_RT',
  LightGBM_NRT = 'LightGBM_NRT',
  LightGBM_NRNT = 'LightGBM_NRNT',
  LightGBM_RNT = 'LightGBM_RNT',
}

export const FORECASTING_METHOD_LABELS: Array<any> = [
  { method: 'PROPHET', label: 'Prophet' },
  { method: 'STL', label: 'ARIMA-based Seasonal & Trend Decomposition' },
  { method: 'DT', label: 'Decision Tree' },
  { method: 'RF', label: 'Random Forest' },
  { method: 'XGB', label: 'XGBoost' },
  { method: 'ADA', label: 'AdaBoost with Decision Tree Estimator' },
  { method: 'ETR', label: 'Extra Trees' },
  { method: 'Naive', label: 'Naive Forecast' },
  { method: 'NaiveSeasonal', label: 'Naive Seasonal Forecast' },
  { method: 'LightGBM_RT', label: 'LightGBM (Recursive, using Target Transform)' },
  { method: 'LightGBM_NRT', label: 'LightGBM (Non-recursive, using Target Transform)' },
  { method: 'LightGBM_NRNT', label: 'LightGBM (Non-recursive, no Target Transform)' },
  { method: 'LightGBM_RNT', label: 'LightGBM (Recursive, no Target Transform)' },
]

export interface WorkspaceDemandForecastAPIRequest {
  workspaceId: string;
  forecastingMethod: ForecastingMethod;
  requiredPeriod: SimpleDateRange;
}

export interface SetBaseDemandAvailabilityParams {
  id: string;
  isAvailable: boolean;
}

export interface GetBaseDemandAvailabilityParams {
  id: string;
}

@Injectable()
export class WorkspaceService {
  constructor(private readonly httpService: HttpService) { }

  checkIsDisplayMonthYearOnly(workspace: Workspace): boolean {
    if (workspace != undefined && workspace.hasOwnProperty("settings")) {
      return workspace.settings?.displayMonthYearOnly ?? false;
    } else {
      return false;
    }
  }

  checkIsDisplayPivotTable(workspace: Workspace): boolean {
    if (workspace != undefined && workspace.hasOwnProperty("settings")) {
      return workspace.settings?.displayPivotTable ?? false;
    } else {
      return false;
    }
  }

  resetRange(range: Date[]) {
    range[0] = new Date(range[0].getFullYear(), range[0].getMonth(), 1);
    range[1] = new Date(range[1].getFullYear(), range[1].getMonth() + 1, 0);
  }

  setBaseDemandAvailability(params: SetBaseDemandAvailabilityParams): Observable<any> {
    return this.httpService.post('explorer/workspaces/base-demand-availability', params);
  }

  getBaseDemandAvailability(workspaceId: string): Observable<any> {
    return this.httpService.get(`explorer/workspaces/base-demand-availability?workspaceId=${workspaceId}`);
  }

  triggerForecaster(params: WorkspaceDemandForecastAPIRequest): Observable<boolean> {
    return this.httpService.post('simcel-async/demand-generation/workspace-forecasting', params);
  }

  getWorkspaceForecastReportURL(workspaceId: string): Observable<any> {
    return this.httpService.get(`explorer/workspaces/get-forecast-report-url?workspaceId=${workspaceId}`);
  }
}
