import { createAction, props } from '@ngrx/store';
import {
  IGetDRPDataParams,
  IDRPLoadedParams,
  IUpdateDRPParams,
  ISitDataResponse,
  ISitActualXResponse
} from '@/app/@core/entity/drp.service';

import { DateAggregationOption } from '@/app/pages/explorer/planning-explorer/widgets/timeseries/timeseries.constants';
import { EditingSitScenario } from '@/app/@core/services/crdt.service';

export const DEFAULT_SIT_CHART_RESPONSE: ISitDataResponse = {
  columns: [],
  rows: [],
  tree: [],
};

export const UPDATE_SIT_GROUPING = createAction(
  '[🌱 Sit Page] Update Sit Grouping',
  props<{ data: string[] }>(),
);

export const UPDATE_SIT_DATE_AGGREGATION = createAction(
  '[🤖 Sit Page] Update Date Aggregation',
  props<{ dateAggregation: DateAggregationOption }>(),
);

export const UPDATE_SIT_UOM = createAction(
  '[🤖 Sit Page] Update Unit of Measure',
  props<{ uom: string }>(),
);

export const UPDATE_SIT_CONSTRAINED = createAction(
  '[🤖 Sit Page] Update SIT Constrained',
  props<{ useConstrained: boolean }>(),
);

export const LOAD_SIT_DATA = createAction(
  '[⚡SIT Page] Load SIT chart data',
  props<{ params: IGetDRPDataParams }>(),
);
export const LOAD_SIT_DATA_SUCCESS = createAction(
  '[🤖SIT Page] Load SIT chart data success',
  props<{ data: ISitDataResponse }>(),
);
export const LOAD_SIT_DATA_FAILED = createAction(
  '[🤖SIT Page] Load SIT chart data failed',
  props<{ error: any }>(),
);

export const LOAD_SIT_MULTI_ACTUAL_X_DATA = createAction(
  '[⚡ 🤖SIT Page] Load SIT Multi Actual X data',
);

export const LOAD_SIT_MULTI_ACTUAL_X_DATA_SUCCESS = createAction(
  '[🤖 🤖SIT Page] Load SIT Multi Actual X data success',
  props<{ data: ISitActualXResponse }>(),
);

export const LOAD_SIT_MULTI_ACTUAL_X_DATA_FAILED = createAction(
  '[🤖 🤖SIT Page] Load SIT Multi Actual X data failed',
  props<{ error: any }>(),
);

export const LOAD_SIT_ACTUAL_X_DATA = createAction(
  '[⚡ 🤖SIT Page] Load SIT chart Actual X data',
  props<{ offset: number }>(),
);

export const UNLOAD_SIT_ACTUAL_X_DATA = createAction(
  '[⚡ 🤖SIT Page] Unload SIT chart Actual X data',
  props<{ key: string }>(),
);

export const LOAD_SIT_ACTUAL_X_DATA_SUCCESS = createAction(
  '[🤖 🤖SIT Page] Load SIT chart Actual X data success',
  props<{ data: ISitActualXResponse }>(),
);

export const LOAD_SIT_ACTUAL_X_DATA_FAILED = createAction(
  '[🤖 🤖SIT Page] Load SIT chart Actual X data failed',
  props<{ error: any }>(),
);

export const UPDATE_DRP_VALUE = createAction(
  '[🤖 SIT Page] Update SIT value',
  props<{ params: IUpdateDRPParams }>(),
)
export const UPDATE_DRP_VALUE_SUCCESS = createAction(
  '[🤖 SIT Page] Update SIT value success',
  props<{ data: ISitDataResponse }>(),
)
export const UPDATE_DRP_VALUE_FAILED = createAction(
  '[🤖 SIT Page] Update SIT value failed',
  props<{ error: any }>(),
)

export const VALIDATE_DRP = createAction(
  '[🤖 SIT Page] Validate SIT value',
  props<{ scenarioId: string }>(),
)
export const VALIDATE_DRP_SUCCESS = createAction(
  '[🤖 SIT Page] Validate SIT value success',
  props<{ scenarioId: string }>(),
)
export const VALIDATE_DRP_FAILED = createAction(
  '[🤖 SIT Page] Validate SIT value failed',
  props<{ error: any }>(),
)

export const TRIGGER_SALES_IN_SIMULATION = createAction('[🤖 SIT Page] Trigger Sales In Simulation')
export const CANCEL_SALES_IN_SIMULATION = createAction('[🤖 SIT Page] Cancel Sales In Simulation')

export const TRIGGER_SALES_IN_SIMULATION_SUCCESS = createAction(
  '[🤖 SIT Page] Trigger Sales In Simulation success',
  props<{ scenarioId: string }>(),
)
export const TRIGGER_SALES_IN_SIMULATION_FAILED = createAction(
  '[🤖 SIT Page] Trigger Sales In Simulation failed',
  props<{ error: any }>(),
)

export const UPDATE_SIT_LOADED_PARAMS = createAction(
  '[🤖 SIT Page] Update sit loaded params',
  props<{ params: IDRPLoadedParams }>(),
);

export const TRIGGER_LOAD_SIT = createAction(
  '[🤖 SIT Page] Trigger load SIT',
)

export const UPDATE_EDITING_SIT_SCENARIOS = createAction(
  '[🤖 SIT Page] Update editing SIT scenarios',
  props<{ editingSitScenarios: EditingSitScenario[] }>(),
)
