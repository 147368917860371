import { ATOMIC_KPIS, Breakdown } from '@/app/@core/interfaces/business/breakdown';
import { DateAggregationOption } from '@/app/pages/explorer/planning-explorer/widgets/timeseries/timeseries.constants';
import { createReducer, on } from '@ngrx/store';
import {
  LOAD_ATOMIC_KPIS_DATA,
  LOAD_ATOMIC_KPIS_DATA_FAILED,
  LOAD_ATOMIC_KPIS_DATA_SUCCESS,
  LOAD_KPIS_DATA,
  LOAD_KPIS_DATA_FAILED,
  LOAD_KPIS_DATA_SUCCESS,
  LOAD_KPI_MAP_DATA,
  LOAD_KPI_MAP_DATA_FAILED,
  LOAD_KPI_MAP_DATA_SUCCESS,
  LOAD_KPI_MATRIX_DATA,
  LOAD_KPI_MATRIX_DATA_FAILED,
  LOAD_KPI_MATRIX_DATA_SUCCESS,
  LOAD_SP_CHART_DATA,
  LOAD_SP_CHART_DATA_FAILED,
  LOAD_SP_CHART_DATA_SUCCESS,
  LOAD_SUPPORTED_CURRENCIES,
  LOAD_SUPPORTED_CURRENCIES_FAILED,
  LOAD_SUPPORTED_CURRENCIES_SUCCESS,
  SELECT_ATOMIC_KPI,
  SET_SPCHART_DECIMAL_PLACES,
  SET_SPCHART_NUMERIC_SCALE,
  SELECT_CURRENCY,
  UPDATE_SP_CHART_DATE_AGG,
  SET_BREAK_DOWN_CHART_DECIMAL_PLACES,
  SET_BREAK_DOWN_CHART_NUMERIC_SCALE,
  LOAD_BUSINESS_DRIVER_DATA,
  LOAD_BUSINESS_DRIVER_DATA_SUCCESS,
  LOAD_BUSINESS_DRIVER_DATA_FAILED,
  SELECT_BUSINESS_DRIVER_MAIN_DIM,
  SELECT_BUSINESS_DRIVER_SECONDARY_DIM,
  SELECT_BUSINESS_DRIVER_MAIN_KPI,
  SELECT_BUSINESS_DRIVER_SECONDARY_KPI,
  SELECT_BUSINESS_DRIVER_SORT_KEY,
  SET_BUSINESS_DRIVER_DECIMAL_PLACES,
  SET_BUSINESS_DRIVER_NUMERIC_SCALE,
  SELECT_KPI_MATRIX_X_AXIS,
  SELECT_KPI_MATRIX_Y_AXIS,
  SELECT_MATRIX_KPI_1,
  SELECT_MATRIX_KPI_2,
  SELECT_MAP_KPI,
  LOAD_COUNTRY_CODES,
  SELECT_COUNTRY_CODES,
  SELECT_KPI_MATRIX_SORT_KEY,
  SET_SCREEN_WIDTH,
  SET_SCENARIO_SCORE_METRICS,
  LOAD_PIVOT_DATA,
  LOAD_PIVOT_DATA_SUCCESS,
  LOAD_PIVOT_DATA_FAILED,
  UPDATE_KPIS_LOADED_PARAMS,
  SELECT_BUSINESS_DRIVER_NUMBER_OF_RESULTS,
} from './planning-explorer.actions';
import { IGetKpisParams } from '@/app/@core/services/kpis.service';
import { AtomicKPIs } from '@/app/@core/services/kpis.service';
import { BusinessDriverData } from '@/app/@core/services/business-driver.service';

export const PAGE__BIZ_STATE_KEY = 'page_biz';

interface Sort {
  by: number;
  top: number;
}

export const SORT_OPTIONS: { key: number; label: string; value: Sort }[] = [
  { key: 1, label: 'KPI 1 - Top 10', value: { by: 1, top: 1 } },
  { key: 2, label: 'KPI 1 - Bottom 10', value: { by: 1, top: -1 } },
  { key: 3, label: 'KPI 2 - Top 10', value: { by: 2, top: 1 } },
  { key: 4, label: 'KPI 2 - Bottom 10', value: { by: 2, top: -1 } },
];

export const KPI_MATRIX_SORT_OPTIONS: { key: number; label: string; value: Sort }[] = [
  { key: 1, label: 'KPI 1 - Top 25', value: { by: 1, top: 25 } },
  { key: 2, label: 'KPI 1 - Top 50', value: { by: 1, top: 50 } },
  { key: 3, label: 'KPI 1 - Top 100', value: { by: 1, top: 100 } },
  { key: 4, label: 'KPI 2 - Top 25', value: { by: 2, top: 25 } },
  { key: 5, label: 'KPI 2 - Top 50', value: { by: 2, top: 50 } },
  { key: 6, label: 'KPI 2 - Top 100', value: { by: 2, top: 100 } },
];

export interface ScenarioScoreMetric {
  shortName: string;
  name: string;
  weight: number;
  max: number;
  min: number;
  isLowerBetter: boolean;
}

interface IGetKpisChartDataResponse {
  columns: string[];
  rows: Array<[string, Array<Breakdown | null>]>;
}

/*************************************
 * State
 *************************************/

export interface IBizPageState {
  kpis?: Record<string, Breakdown>;
  kpis_loading: boolean;
  kpis_error?: any;
  kpisLoadedParams?: IGetKpisParams | {};
  spChart?: IGetKpisChartDataResponse;
  spChart_loading: boolean;
  spChart_error?: any;
  spChart_dateAgg: DateAggregationOption;
  atomicKpis?: any;
  atomicKpis_loading: boolean;
  atomicKpis_error?: any;
  selectedAtomicKpi: string;
  isAtomicKpis: boolean;
  spChartNumericScale: string;
  spChartDecimalPlaces: number;
  breakdownChartNumericScale: string;
  breakdownChartDecimalPlaces: number;
  selectedCurrency: string;
  supportedCurrencies: string[];
  supportedCurrencies_loading: boolean;
  supportedCurrencies_error?: any;
  businessDriverData: BusinessDriverData[];
  businessDriverData_loading: boolean;
  businessDriverData_error?: any;
  mainDim: string;
  secondaryDim: string;
  mainKPI: string;
  secondaryKPI: string;
  sortKey: number;
  numOfResults: number;
  businessDriverChartNumericScale: string;
  businessDriverChartDecimalPlaces: number;
  kpiMatrixData: any;
  kpiMatrix_loading: boolean;
  kpiMatrix_error?: any;
  kpiMapData: any;
  kpiMap_loading: boolean;
  kpiMap_error?: any;
  selectedMapKpi: string;
  kpiMatrixXAxis: string;
  kpiMatrixYAxis: string;
  selectedMatrixKpi1: string;
  selectedMatrixKpi2: string;
  kpiMatrixSortKey: number;
  countryCodes: string[];
  selectedCountryCodes: string[];
  screenWidth: number;
  scenarioScoreMetrics: ScenarioScoreMetric[];
  pivotData?: any;
  pivotData_loading: boolean;
  pivotData_error?: any;
}

export const initialState: IBizPageState = {
  kpis_loading: false,
  kpisLoadedParams: {},
  spChart_loading: false,
  spChart_dateAgg: DateAggregationOption.MONTH,
  atomicKpis_loading: false,
  selectedAtomicKpi: AtomicKPIs.FIEDemandValue,
  isAtomicKpis: false,
  spChartNumericScale: 'D',
  spChartDecimalPlaces: 0,
  breakdownChartNumericScale: 'D',
  breakdownChartDecimalPlaces: 0,
  selectedCurrency: '',
  supportedCurrencies: [],
  supportedCurrencies_loading: false,
  businessDriverData: [],
  businessDriverData_loading: false,
  mainDim: 'ProductID',
  secondaryDim: 'ProductName',
  mainKPI: ATOMIC_KPIS[0].name,
  secondaryKPI: ATOMIC_KPIS[1].name,
  sortKey: 1,
  numOfResults: 10,
  businessDriverChartNumericScale: 'U',
  businessDriverChartDecimalPlaces: 1,
  kpiMatrixData: [],
  kpiMatrix_loading: false,
  kpiMapData: [],
  kpiMap_loading: false,
  selectedMapKpi: AtomicKPIs.FIEDemandValue,
  kpiMatrixXAxis: 'ProductName',
  kpiMatrixYAxis: 'Name',
  selectedMatrixKpi1: AtomicKPIs.FIEDemandValue,
  selectedMatrixKpi2: AtomicKPIs.FIEDemandVolume,
  kpiMatrixSortKey: 1,
  countryCodes: ['VNM'],
  selectedCountryCodes: ['VNM'],
  screenWidth: window.innerWidth,
  scenarioScoreMetrics: [],
  pivotData_loading: false,
};

/*************************************
 * Reducers
 *************************************/

export const page_bizReducer = createReducer(
  initialState,
  on(
    LOAD_KPIS_DATA,
    (state): IBizPageState => ({
      ...state,
      kpis_loading: true,
      kpis_error: undefined,
    }),
  ),
  on(
    LOAD_KPIS_DATA_SUCCESS,
    (state, { data }): IBizPageState => ({
      ...state,
      kpis: data,
      kpis_loading: false,
      kpis_error: undefined,
    }),
  ),
  on(
    LOAD_KPIS_DATA_FAILED,
    (state, { error }): IBizPageState => ({
      ...state,
      kpis_loading: false,
      kpis_error: error,
    }),
  ),
  on(
    UPDATE_KPIS_LOADED_PARAMS,
    (state, { params }): IBizPageState => ({
      ...state,
      kpisLoadedParams: params,
    }),
  ),
  on(
    LOAD_SP_CHART_DATA,
    (state): IBizPageState => ({
      ...state,
      spChart_loading: true,
      spChart_error: undefined,
    }),
  ),
  on(
    LOAD_SP_CHART_DATA_SUCCESS,
    (state, { data }): IBizPageState => ({
      ...state,
      spChart: data,
      spChart_loading: false,
      spChart_error: undefined,
    }),
  ),
  on(
    LOAD_SP_CHART_DATA_FAILED,
    (state, { error }): IBizPageState => ({
      ...state,
      spChart_loading: false,
      spChart_error: error,
    }),
  ),
  on(
    UPDATE_SP_CHART_DATE_AGG,
    (state, { option }): IBizPageState => ({ ...state, spChart_dateAgg: option }),
  ),
  on(
    LOAD_ATOMIC_KPIS_DATA,
    (state): IBizPageState => ({
      ...state,
      atomicKpis_loading: true,
      atomicKpis_error: undefined,
    }),
  ),
  on(
    LOAD_ATOMIC_KPIS_DATA_SUCCESS,
    (state, { data }): IBizPageState => ({
      ...state,
      atomicKpis: data,
      atomicKpis_loading: false,
      atomicKpis_error: undefined,
    }),
  ),
  on(
    LOAD_ATOMIC_KPIS_DATA_FAILED,
    (state, { error }): IBizPageState => ({
      ...state,
      atomicKpis_loading: false,
      atomicKpis_error: error,
    }),
  ),
  on(
    SELECT_ATOMIC_KPI,
    (state, { selectedAtomicKpi }): IBizPageState => ({ ...state, selectedAtomicKpi }),
  ),

  on(
    SET_SPCHART_DECIMAL_PLACES,
    (state, { spChartDecimalPlaces }): IBizPageState => ({
      ...state,
      spChartDecimalPlaces,
    }),
  ),
  on(
    SET_SPCHART_NUMERIC_SCALE,
    (state, { spChartNumericScale }): IBizPageState => ({
      ...state,
      spChartNumericScale,
    }),
  ),
  on(
    SET_BREAK_DOWN_CHART_DECIMAL_PLACES,
    (state, { breakdownChartDecimalPlaces }): IBizPageState => ({
      ...state,
      breakdownChartDecimalPlaces,
    }),
  ),
  on(
    SET_BREAK_DOWN_CHART_NUMERIC_SCALE,
    (state, { breakdownChartNumericScale }): IBizPageState => ({
      ...state,
      breakdownChartNumericScale,
    }),
  ),
  on(
    SELECT_CURRENCY,
    (state, { selectedCurrency }): IBizPageState => ({ ...state, selectedCurrency }),
  ),
  on(
    LOAD_SUPPORTED_CURRENCIES,
    (state): IBizPageState => ({
      ...state,
      supportedCurrencies_loading: true,
      supportedCurrencies_error: undefined,
    }),
  ),
  on(
    LOAD_SUPPORTED_CURRENCIES_SUCCESS,
    (state, { data }): IBizPageState => ({
      ...state,
      supportedCurrencies: data,
      supportedCurrencies_loading: false,
      supportedCurrencies_error: undefined,
    }),
  ),
  on(
    LOAD_SUPPORTED_CURRENCIES_FAILED,
    (state, { error }): IBizPageState => ({
      ...state,
      supportedCurrencies_loading: false,
      supportedCurrencies_error: error,
    }),
  ),
  on(
    LOAD_BUSINESS_DRIVER_DATA,
    (state): IBizPageState => ({
      ...state,
      businessDriverData_loading: true,
      businessDriverData_error: undefined,
    }),
  ),
  on(
    LOAD_BUSINESS_DRIVER_DATA_SUCCESS,
    (state, { data }): IBizPageState => ({
      ...state,
      businessDriverData: data,
      businessDriverData_loading: false,
      businessDriverData_error: undefined,
    }),
  ),
  on(
    LOAD_BUSINESS_DRIVER_DATA_FAILED,
    (state, { error }): IBizPageState => ({
      ...state,
      businessDriverData_loading: false,
      businessDriverData_error: error,
    }),
  ),
  on(
    SELECT_BUSINESS_DRIVER_MAIN_DIM,
    (state, { mainDim }): IBizPageState => ({ ...state, mainDim }),
  ),
  on(
    SELECT_BUSINESS_DRIVER_SECONDARY_DIM,
    (state, { secondaryDim }): IBizPageState => ({ ...state, secondaryDim }),
  ),
  on(
    SELECT_BUSINESS_DRIVER_MAIN_KPI,
    (state, { mainKPI }): IBizPageState => ({ ...state, mainKPI }),
  ),
  on(
    SELECT_BUSINESS_DRIVER_SECONDARY_KPI,
    (state, { secondaryKPI }): IBizPageState => ({ ...state, secondaryKPI }),
  ),
  on(
    SELECT_BUSINESS_DRIVER_SORT_KEY,
    (state, { sortKey }): IBizPageState => ({ ...state, sortKey }),
  ),
  on(
    SELECT_BUSINESS_DRIVER_NUMBER_OF_RESULTS,
    (state, { numOfResults }): IBizPageState => ({ ...state, numOfResults }),
  ),
  on(
    SET_BUSINESS_DRIVER_DECIMAL_PLACES,
    (state, { businessDriverChartDecimalPlaces }): IBizPageState => ({
      ...state,
      businessDriverChartDecimalPlaces,
    }),
  ),
  on(
    SET_BUSINESS_DRIVER_NUMERIC_SCALE,
    (state, { businessDriverChartNumericScale }): IBizPageState => ({
      ...state,
      businessDriverChartNumericScale,
    }),
  ),
  on(
    LOAD_KPI_MATRIX_DATA,
    (state): IBizPageState => ({
      ...state,
      kpiMatrix_loading: true,
      kpiMatrix_error: undefined,
    }),
  ),
  on(
    LOAD_KPI_MATRIX_DATA_SUCCESS,
    (state, { data }): IBizPageState => ({
      ...state,
      kpiMatrixData: data,
      kpiMatrix_loading: false,
      kpiMatrix_error: undefined,
    }),
  ),
  on(
    LOAD_KPI_MATRIX_DATA_FAILED,
    (state, { error }): IBizPageState => ({
      ...state,
      kpiMatrix_loading: false,
      kpiMatrix_error: error,
    }),
  ),
  on(
    SELECT_KPI_MATRIX_X_AXIS,
    (state, { kpiMatrixXAxis }): IBizPageState => ({ ...state, kpiMatrixXAxis }),
  ),
  on(
    SELECT_KPI_MATRIX_Y_AXIS,
    (state, { kpiMatrixYAxis }): IBizPageState => ({ ...state, kpiMatrixYAxis }),
  ),
  on(
    SELECT_MATRIX_KPI_1,
    (state, { selectedMatrixKpi1 }): IBizPageState => ({ ...state, selectedMatrixKpi1 }),
  ),
  on(
    SELECT_MATRIX_KPI_2,
    (state, { selectedMatrixKpi2 }): IBizPageState => ({ ...state, selectedMatrixKpi2 }),
  ),
  on(
    SELECT_KPI_MATRIX_SORT_KEY,
    (state, { sortKey }): IBizPageState => ({ ...state, kpiMatrixSortKey: sortKey }),
  ),
  on(
    LOAD_KPI_MAP_DATA,
    (state): IBizPageState => ({
      ...state,
      kpiMap_loading: true,
      kpiMap_error: undefined,
    }),
  ),
  on(
    LOAD_KPI_MAP_DATA_SUCCESS,
    (state, { data }): IBizPageState => ({
      ...state,
      kpiMapData: data,
      kpiMap_loading: false,
      kpiMap_error: undefined,
    }),
  ),
  on(
    LOAD_KPI_MAP_DATA_FAILED,
    (state, { error }): IBizPageState => ({
      ...state,
      kpiMap_loading: false,
      kpiMap_error: error,
    }),
  ),
  on(SELECT_MAP_KPI, (state, { selectedMapKpi }): IBizPageState => ({ ...state, selectedMapKpi })),
  on(LOAD_COUNTRY_CODES, (state, { countryCodes }): IBizPageState => ({ ...state, countryCodes })),
  on(
    SELECT_COUNTRY_CODES,
    (state, { selectedCountryCodes }): IBizPageState => ({ ...state, selectedCountryCodes }),
  ),
  on(SET_SCREEN_WIDTH, (state, { screenWidth }): IBizPageState => ({ ...state, screenWidth })),
  on(
    SET_SCENARIO_SCORE_METRICS,
    (state, { scenarioScoreMetrics }): IBizPageState => ({ ...state, scenarioScoreMetrics }),
  ),
  on(
    LOAD_PIVOT_DATA,
    (state): IBizPageState => ({
      ...state,
      pivotData_loading: true,
      pivotData_error: undefined,
    }),
  ),
  on(
    LOAD_PIVOT_DATA_SUCCESS,
    (state, { data }): IBizPageState => ({
      ...state,
      pivotData: data,
      pivotData_loading: false,
      pivotData_error: undefined,
    }),
  ),
  on(
    LOAD_PIVOT_DATA_FAILED,
    (state, { error }): IBizPageState => ({
      ...state,
      pivotData_loading: false,
      pivotData_error: error,
    }),
  ),
);
