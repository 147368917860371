import { Injectable } from '@angular/core';

import { HttpService } from '../backend/common/api/http.service';
import { SimpleDateRange } from '../interfaces/common/date-range';
import { Segment } from '../interfaces/business/segment';
import { DateAggregationOption } from '@/app/pages/explorer/planning-explorer/widgets/timeseries/timeseries.constants';
import { from, mergeMap, Observable, reduce, retry, map } from 'rxjs';
import { IDemandActualXResponse, IDemandChartResponse } from '@/store/pages/demand-planning/demand-planning.actions';
import { subtractYearsFromDateRange } from '@/utils/calendar';
import { SegmentProxy } from '../interfaces/business/event';
import { BaseCacheService } from '../services/base-cache.service';

export interface IGetDemandsChartDataParams {
  interval?: DateAggregationOption;
  segment?: Segment;
  dateRange?: SimpleDateRange;
  groupingColumns: string[];
  planId: string;
  force: boolean;
  workspaceId: string;
  forecastCollection: string;
  uom?: string;
  isActual?: boolean;
}

export interface IGetDemandImpactsChartDataParams extends IGetDemandsChartDataParams {
  scenarios: Array<string | { id: string; events: string[] }>;
}
interface IGetDemandParams {
  database: string;
  segment: Segment;
  dateRange: SimpleDateRange;
  groupingColumns?: string[];
}
export interface IGetScenarioDemandParams extends IGetDemandParams {
  eventVersionIds: string[];
  segmentDatabase: string;
  planId: string;
  companyId: string;
  workspaceId: string;
  forceRun: boolean;
}

const enum DemandType {
  Actual = 'actual',
  Committed = 'committed',
  Current = 'current',
  Forecast = 'forecast',
  BaseDemand = 'baseDemand',
}

export enum AnalyticsTypes {
  FY_TOTAL = 'FY Total',
  QUARTER_TOTAL = 'Quarter Total',
  HALF_YEAR_TOTAL = 'Half-year Total',
  FY_AVERAGE = 'FY Average',
  QUARTER_AVERAGE = 'Quarter Average',
  HALF_YEAR_AVERAGE = 'Half-year Average',
  YTD_TOTAL = 'YTD Total',
  YTG_TOTAL = 'YTG Total',
  YTD_AVERAGE = 'YTD Average',
  YTG_AVERAGE = 'YTG Average',
  DIFF_YTD_YTG = 'Diff YTD - YTG',
  PERCENTAGE_DIFF_YTD_YTG = '% Diff YTD - YTG',
  DIFF_AVG_YTD_YTG = 'Diff Avg YTD - YTG',
  PERCENTAGE_DIFF_AVG_YTD_YTG = '% Diff Avg YTD - YTG',
}

export const AnalyticsGroupMappings: { [key in AnalyticsTypes]: string[] } = {
  [AnalyticsTypes.FY_TOTAL]: ['Y'],
  [AnalyticsTypes.QUARTER_TOTAL]: ['Q1', 'Q2', 'Q3', 'Q4'],
  [AnalyticsTypes.HALF_YEAR_TOTAL]: ['H1', 'H2'],
  [AnalyticsTypes.FY_AVERAGE]: ['Y Avg'],
  [AnalyticsTypes.QUARTER_AVERAGE]: ['Q1 Avg', 'Q2 Avg', 'Q3 Avg', 'Q4 Avg'],
  [AnalyticsTypes.HALF_YEAR_AVERAGE]: ['H1 Avg', 'H2 Avg'],
  [AnalyticsTypes.YTD_TOTAL]: ['YTD'],
  [AnalyticsTypes.YTG_TOTAL]: ['YTG'],
  [AnalyticsTypes.YTD_AVERAGE]: ['Avg YTD'],
  [AnalyticsTypes.YTG_AVERAGE]: ['Avg YTG'],
  [AnalyticsTypes.DIFF_YTD_YTG]: ['Var'],
  [AnalyticsTypes.PERCENTAGE_DIFF_YTD_YTG]: ['Var%'],
  [AnalyticsTypes.DIFF_AVG_YTD_YTG]: ['Avg Var'],
  [AnalyticsTypes.PERCENTAGE_DIFF_AVG_YTD_YTG]: ['Avg Var%'],
};

export interface IGetDemandsByProxyParams {
  segmentProxies: SegmentProxy[];
  planId: string;
}

const DELAY_SECOND = 10 + (Math.random() * 10);
const RETRY_COUNT = 5;

@Injectable({ providedIn: 'root' })
export class DemandService extends BaseCacheService{
  constructor(private readonly api: HttpService) {
    super();
  }

  getDemands(params: IGetDemandsChartDataParams): Observable<IDemandChartResponse> {
    const types = [
      DemandType.Actual,
    ];

    if (params.isActual) {
      types.push(DemandType.Committed, DemandType.BaseDemand);
    } else {
      types.push(DemandType.Forecast, DemandType.Current);
    }
    const init: IDemandChartResponse = {
      columns: [],
      rows: [],
      tree: [],
    };

    return from(types).pipe(
      mergeMap(type => this.cacheOrFetch('demands', { ...params, demandTypes: [type] }, (endpoint, fetchParams) =>
        this.api.post(endpoint, fetchParams).pipe(
          retry({ count: RETRY_COUNT, delay: DELAY_SECOND * 1000 }),
        )
      )),
      reduce((res, value) => {
        if (!value?.columns) {
          return res;
        }
        if (res.columns.length < 1) {
          res.columns = value.columns;
        }
        res.rows.push(...value.rows);
        res.tree.push(...value.tree);
        return res;
      }, init),
    )
  }

  getDemandsBySegmentProxy(params: IGetDemandsByProxyParams) {
    return this.api.post('demands/historical-demands-by-proxy', params);
  }

  getDemandsForMultiActualX(params: IGetDemandsChartDataParams, offsets: number[]): Observable<IDemandActualXResponse> {
    const cases = offsets.map(offset => ({
      type: DemandType.Actual,
      offset,
      dateRange: subtractYearsFromDateRange(params.dateRange as SimpleDateRange, offset)
    }))
    const init: IDemandActualXResponse = {
      columns: [],
      rows: [],
      tree: [],
    };

    return from(cases).pipe(
      mergeMap(c => {
        return this.cacheOrFetch('demands', { ...params, dateRange: c.dateRange, demandTypes: [c.type] }, (endpoint, fetchParams) => 
          this.api.post(endpoint, fetchParams).pipe(
            map(value => ({ value, c }))
          )
        );
      }),
      reduce((res, { value, c }) => {
        if (!value?.columns) {
          return res;
        }

        if (res.columns.length < 1) {
          res.columns = [[`actual-${c.offset}`, value.columns] as [string, (string | null)[]]];
        } else {
          res.columns.push([`actual-${c.offset}`, value.columns] as [string, (string | null)[]]);
        }

        res.rows.push(
          ...value.rows
            .filter(r => r[0] === 'actual')
            .map(
              (r: [string, (number | null)[]]) => (
                [`actual-${c.offset}`, r[1]] as [string, (number | null)[]]
              )
            )
        );
        res.tree.push(
          ...value.tree
            .filter(t => t.key === 'actual')
            .map(
              t => ({
                ...t,
                key: `actual-${c.offset}`
              })
            )
        );

        return res;
      }, init),
    )
  }

  getDemandsForActualX(params: IGetDemandsChartDataParams, offset: number): Observable<IDemandActualXResponse> {
    const types = [
      DemandType.Actual,
    ];
    const init: IDemandActualXResponse = {
      columns: [],
      rows: [],
      tree: [],
    };
    const updatedParams = {
      ...params,
      dateRange: subtractYearsFromDateRange(params.dateRange as SimpleDateRange, offset)
    };

    return from(types).pipe(
      mergeMap(type => this.cacheOrFetch('demands', { ...updatedParams, demandTypes: [type] }, (endpoint, fetchParams) =>
        this.api.post(endpoint, fetchParams).pipe(
          retry({ count: RETRY_COUNT, delay: DELAY_SECOND * 1000 }),
        )
      )),
      reduce((res, value) => {
        if (!value?.columns) {
          return res;
        }

        if (res.columns.length < 1) {
          res.columns = [[`actual-${offset}`, value.columns] as [string, (string | null)[]]];
        } else {
          res.columns.push([`actual-${offset}`, value.columns] as [string, (string | null)[]]);
        }

        res.rows.push(
          ...value.rows
            .filter(r => r[0] === 'actual')
            .map(
              (r: [string, (number | null)[]]) => (
                [`actual-${offset}`, r[1]] as [string, (number | null)[]]
              )
            )
        );
        res.tree.push(
          ...value.tree
            .filter(t => t.key === 'actual')
            .map(
              t => ({
                ...t,
                key: `actual-${offset}`
              })
            )
        );

        return res;
      }, init),
    )
  }

  getDemandImpacts(params: IGetDemandImpactsChartDataParams) {
    return this.cacheOrFetch('demands/impact', params, (endpoint, fetchParams) => 
      this.api.post(endpoint, fetchParams).pipe(
        retry({ count: RETRY_COUNT, delay: DELAY_SECOND * 1000 })
      )
    );
  }

  updateConsolidateCommittedPlansDemand(workspaceId: string, planId: string) {
    return this.api.post('demands/update-committed-plans-demand', { workspaceId: workspaceId, usingOffset: false, planId: planId });
  }

  private getByDemandType(params: IGetDemandsChartDataParams, type: string): Observable<IDemandChartResponse> {
    return this.api.post('demands', {
      ...params,
      demandTypes: [type]
    }).pipe(
      retry({ count: RETRY_COUNT, delay: DELAY_SECOND * 1000 }),
    )
  }
}
