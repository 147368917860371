import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Scenario } from '../interfaces/business/scenario';

@Injectable({ providedIn: 'root' })
export class EventStateService {
  private loadingStates = new Map<string, BehaviorSubject<boolean>>();

  getLoadingState(key: string): Observable<boolean> {
    if (!this.loadingStates.has(key)) {
      this.loadingStates.set(key, new BehaviorSubject<boolean>(false));
    }
    return this.loadingStates.get(key)!.asObservable();
  }

  setLoadingState(key: string, isLoading: boolean) {
    if (!this.loadingStates.has(key)) {
      this.loadingStates.set(key, new BehaviorSubject<boolean>(isLoading));
    } else {
      this.loadingStates.get(key)!.next(isLoading);
    }
  }
}
