import { createSelector, createFeatureSelector } from '@ngrx/store';
import { color, EChartsOption } from 'echarts';
import { pick } from 'rambda';
import { endOfMonth, startOfMonth, isBefore, parseISO } from 'date-fns';

import { IDemandPlanningPageState, PAGE__DEMAND_PLANNING_STATE_KEY } from './demand-planning.state';
import {
  select_displayingScenarios,
  select_highlightedScenario,
  select_legacy_actualAndCommittedScenarios,
} from '@/store/scenario/scenario.selectors';
import { environment } from '@/environments/environment';
import { select_selectedDateRange, select_selectedPlan } from '../layout/layout.selectors';
import { select_selectedSegment } from '../layout/layout.baseSelectors';
import { DateAggregationOption } from '@/app/pages/explorer/planning-explorer/widgets/timeseries/timeseries.constants';
import { seriesPairFormatterFactory_v2 } from '@/app/pages/explorer/planning-explorer/widgets/timeseries/timeseries.utils';
import {
  AnalyticsTypes,
  IGetDemandImpactsChartDataParams,
  IGetDemandsChartDataParams,
} from '@/app/@core/entity/demand.service';
import { transformInputToKpiFormat } from '@/app/pipes/kpi-formatting.pipe';
import { nullAcceptedWithSelectedPlan, sumIfHaveValue } from '@/utils/numbers';
import { IDemandChartResponse, IDemandTreeNode } from './demand-planning.actions';
import {
  analyticsTypesOrderCreator,
  calculateAverages,
  calculateDataArea,
  calculateY2YDiff,
  checkAnalyticType,
  combineDemandTree,
  computeTotalLookupByNames,
  DataIndexByYear,
  DEFAULT_ECHARTS_OPTIONS,
  DEFAULT_ECHARTS_SERIES_OPTIONS,
  formatByDateAggregationGen,
  generateShortedScenarioLegend,
  getY2YData,
  propsToColumns,
  removeDuplicates,
  splitAnalyticTitle,
  takeRowDataById,
  takeTreeNodeChildrenById,
  Y2YData,
  calculateTrendLine,
  MONTH_END_KPIS,
  PERCENT_KPIS,
  currencyConversionLookup,
  formatFiscalYearForColumnTitles
} from '@/store/pages/demand-planning/demand-planning.utils';
import { PlanFlag } from '@/app/@core/interfaces/business/plan';
import { ScenarioFlag } from '@/app/@core/interfaces/business/scenario';
import { select_latestActual } from '@/store/actual/actual.selectors';
import { select_selectedWorkspace } from '@/store/workspace/workspace.selectors';

const selectFeature = createFeatureSelector<IDemandPlanningPageState>(
  PAGE__DEMAND_PLANNING_STATE_KEY,
);

export const select_forecastURL = createSelector(
  select_displayingScenarios,
  select_legacy_actualAndCommittedScenarios,
  (scenarios, legacy_actualAndCommittedScenarios) => {
    let scenario_ids = scenarios.map((s) => s.id);
    if (scenario_ids.includes(ScenarioFlag.ACTUAL))
      scenario_ids = legacy_actualAndCommittedScenarios.map((s) => s.id);
    const url = `${environment.forecastExplorerUrl}?scenario_id=${scenario_ids.join(',')}`;
    return url;
  },
);

export const select_dateAggregation = createSelector(
  selectFeature,
  (state) => state.dateAggregation,
);
export const select_uom = createSelector(
  selectFeature,
  (state) => state.uom,
);
export const select_demandGroupings = createSelector(selectFeature, (state) => state.groupings);
export const select_kpiTrackerGroupings = createSelector(selectFeature, (state) => state.kpiTrackerGroupings);
export const select_demandDatasetYearGroupings = createSelector(selectFeature, (state) => state.datasetYearGroupings);
export const select_kpiTrackerDatasetYearGroupings = createSelector(selectFeature, (state) => state.kpiTrackerDatasetYearGroupings);
export const select_demandAnalyticGroupings = createSelector(selectFeature, (state) => state.analyticGroupings);
export const select_kpiTrackerAnalyticGroupings = createSelector(selectFeature, (state) => state.kpiTrackerAnalyticGroupings);
export const select_rawDemands = createSelector(selectFeature, (state) => state.demands);
export const select_demandsActualX = createSelector(selectFeature, (state) => state.demandsActualX);
export const select_demandsActualXLoadingCount = createSelector(selectFeature, (state) => state.demandsActualXLoadingCount);
export const select_decimalPlaces = createSelector(selectFeature, (state) => state.decimalPlaces);
export const select_numericScale = createSelector(selectFeature, (state) => state.numericScale);
export const select_demands_loading = createSelector(
  selectFeature,
  (state) => state.demands_loading || state.impacts_loading,
);
export const select_demands_api_loading = createSelector(
  selectFeature,
  (state) => state.demands_loading,
);
export const select_impacts_api_loading = createSelector(
  selectFeature,
  (state) => state.impacts_loading,
);

export const select_impacts_data = createSelector(
  selectFeature,
  (state) => state.impacts,
);

export const select_demandsByProxy = createSelector(selectFeature, (state) => state.demandsByProxy);
export const select_demandsByProxyLoading = createSelector(selectFeature, (state) => state.demandsByProxyLoading);
export const select_listCurrencies = createSelector(selectFeature, (state) => state.listCurrencies);

export const select_demandLoadedParams = createSelector(
  selectFeature,
  (state) => state.demandLoadedParams,
);

export const select_combinedDemandsChartData = createSelector(
  selectFeature,
  select_selectedPlan,
  select_latestActual,
  select_displayingScenarios,
  select_listCurrencies,
  select_uom,
  (state, selectedPlan, latestActual, displayingScenarios, listCurrencies, uom): IDemandChartResponse => {
    if (!selectedPlan || !latestActual) {
      return { columns: [], rows: [], tree: [] };
    }

    // add total to: columns, rows and tree 
    const scenarios = displayingScenarios.map(pick(['id', 'name']));
    const initialHasActualId = scenarios.some((s) => s.id === 'actual');
    const initialHasCurrentId = scenarios.some((s) => s.id === 'current');
    // If either initialHasActualId or initialHasCurrentId is true, incorporate them into the tree for ForecastBase calculation via table.mergeHistoricalData().
    // Subsequently (in select_combinedDemandsTableDataWithExtraColumns), remove actual or current from the tree if they aren't present in displayingScenarios,
    // as they are not intended to be displayed in the demand table.
    if (!initialHasActualId) {
      scenarios.push({ id: 'actual', name: 'Actual' });
    }
    if (!initialHasCurrentId) {
      scenarios.push({ id: 'current', name: 'Last Plan' });
    }
    const getCurrencyRate = uom === 'Unit' ? () => 1 : currencyConversionLookup(listCurrencies);

    const rows: IDemandChartResponse['rows'] = scenarios.map((s) => {
      // Find the demands data for scenario, and use Forecast base data if not found
      let demands: any = [];
      let impacts: any = [];
      if (s?.id?.includes("actual-")) {
        demands = takeRowDataById(s.id, state.demandsActualX.rows) || [];
      } else {
        demands =
          takeRowDataById(s.id, state.demands.rows) ||
          takeRowDataById('forecast', state.demands.rows) ||
          [];
        impacts = takeRowDataById(s.id, state.impacts.rows) || [];
      }

      // Combine demand data with demand impact data
      // if val and impacts[i] = null , they are 0
      return [s.id, demands.map((val, i) => {
        const dateColumn = state.demands.columns[i];
        const rate = getCurrencyRate(dateColumn) || 1;
        const sumValue = sumIfHaveValue(
          val ? val : nullAcceptedWithSelectedPlan(s.id, selectedPlan, latestActual, dateColumn),
          impacts[i] ? impacts[i] : nullAcceptedWithSelectedPlan(s.id, selectedPlan, latestActual, dateColumn));
        return sumValue != null ? rate * sumValue : sumValue;
      })];
    });

    // For Demand Table's tree data, does not need to add Acutal & Current
    const tree: IDemandChartResponse['tree'] = scenarios.map((s) => {
      let demands: any = [];
      let impacts: any = [];
      if (s?.id?.includes("actual-")) {
        demands = takeTreeNodeChildrenById(s.id, state.demandsActualX.tree) || [];
      } else {
        demands =
          takeTreeNodeChildrenById(s.id, state.demands.tree) ||
          takeTreeNodeChildrenById('forecast', state.demands.tree) ||
          [];
        impacts = takeTreeNodeChildrenById(s.id, state.impacts.tree) || [];
      }
      
      return <IDemandTreeNode>{
        key: s.id,
        label: s.name,
        data: takeRowDataById(s.id, rows), // Take already combined data in rows
        children: combineDemandTree(demands, impacts, state.demands.columns, getCurrencyRate),
      };
    });

    const columns = state.demands.columns;
    const actualRow = rows.find(row => row[0] === "actual")?.[1] || [];
    const currentRow = rows.find(row => row[0] === "current")?.[1] || [];
    const planStartDate = parseISO(selectedPlan?.defaultPlanDisplayStartDate || '');
    const displayedStartDate = parseISO(columns[0]);
    const firstNullIndex = actualRow.findIndex(x => x == null);

    const actualLastDefinedIndex = firstNullIndex >= 0 ? firstNullIndex - 1 : actualRow.length - 1;
    const currentFirstDefinedIndex = currentRow.findIndex(x => x != null);

    const hasGapBeforeStartOfFuture = actualLastDefinedIndex === -1 && isBefore(displayedStartDate, planStartDate);
    const hasGapBetweenActualCurrent = actualLastDefinedIndex > -1 && actualLastDefinedIndex < currentFirstDefinedIndex;

    const fillingData = {
      actualColumns: columns.slice(0, actualLastDefinedIndex + 1),
      lastPlanColumns: currentFirstDefinedIndex === -1 ? [] : columns.slice(currentFirstDefinedIndex),
      hasGapBeforeStartOfFuture,
      hasGapBetweenActualCurrent
    };
    
    const actualEndDate = latestActual?.actualEndDate;
    const actualEndDateIndex = columns.findIndex(date => date.substring(0, 7) === ((actualEndDate as unknown) as string).substring(0, 7));
    const lastYearData = actualRow.filter((_, i) => i <= actualEndDateIndex).slice(-12);
    const runRates = calculateAverages(lastYearData);

    return { columns, rows, tree, fillingData, runRates };
  },
);

interface AddSumArg {
  start: number;
  end: number;
  label: string;
  target: number;
}

interface AnalyticCaseParams {
  numOfMonths: number;
  hasArgument: boolean;
  argumentArray: AddSumArg[];
  counterObj: {
    value: number;
  };
  afterFiscalYearPointer: number;
  label: string;
  start: number;
  end: number;
  fiscalYearStartMonth: number;
}

interface AnalyticColumnParams {
  analyticGroupings: string[];
  actualEndDate: Date;
  futurePlanStartDate: Date;
  futurePlanEndDate: Date;
  selectedAtomicKpi?: string;
  fiscalYearStartMonth: number;
}

interface AddAnalyticsParams {
  args: AddSumArg[];
  key: string;
  actualEndDate: Date;
  futurePlanStartDate: Date;
  futurePlanEndDate: Date;
  dateColumns: string[];
  selectedAtomicKpi?: string;
}

type OpFunc = (start: number, end: number) => number;
type LastMonthFunc = (end: number) => number;

export class DemandTableRow {
  constructor(node: IDemandTreeNode) {
    this.label = node.label;
    this.key = node.key;
    this.data = node.data || [];
    this.diff = [];
    this.children = (node.children || []).map(c => new DemandTableRow(c));
  }

  label = '';
  key = '';
  children: DemandTableRow[] = [];
  data: (number | null)[] = [];
  diff: number[] = [];
  y2yDiff: DataIndexByYear | undefined = undefined;
  y2yDiffPercent: DataIndexByYear | undefined = undefined;

  replaceNullsWithLastPlan(sourceData, startIndex, cData, lastActualIndex) {
    // Merge Last Plan if Actual does not exist and only use cData after lastActualIndex
    return sourceData.map((element, index) => {
      const absoluteIndex = startIndex + index;
      return element == null && absoluteIndex > lastActualIndex 
          ? cData[absoluteIndex] 
          : element;
    });
  }

  mergeWith(actual: DemandTableRow, current: DemandTableRow, fromIndex: number, toIndex: number, lastActualIndex: number) {
    // Helper function to manage the data merging
    const manageData = (sourceData, targetData) => {
      if (sourceData && targetData) {
        return [
          ...this.replaceNullsWithLastPlan(sourceData.slice(fromIndex, toIndex), fromIndex, targetData, lastActualIndex),
          ...this.replaceNullsWithLastPlan(this.data.slice(toIndex), toIndex, current.data, lastActualIndex),
        ];
      }

      return sourceData
        ? [...sourceData.slice(fromIndex, toIndex), ...this.data.slice(toIndex)]
        : [...this.replaceNullsWithLastPlan(this.data.slice(fromIndex, toIndex), fromIndex, targetData, lastActualIndex),
        ...this.replaceNullsWithLastPlan(this.data.slice(toIndex), toIndex, targetData, lastActualIndex)];
    };

    // Applying manageData function based on the availability of actual and current data
    this.data = actual?.data || current?.data
      ? manageData(actual?.data, current?.data)
      : this.data;

    // Handling children if they exist
    if (actual.children || current.children) {
      const actualChildrenMap = new Map(actual.children?.map(child => [child.label, child]));
      const currentChildrenMap = new Map(current.children?.map(child => [child.label, child]));

      // Merging each child based on their label
      this.children.forEach((c, i) => {
        const matchingActualChild = actualChildrenMap.get(c.label);
        const matchingCurrentChild = currentChildrenMap.get(c.label);

        c.mergeWith(
          matchingActualChild || {} as DemandTableRow,
          matchingCurrentChild || {} as DemandTableRow,
          fromIndex,
          toIndex,
          lastActualIndex
        );
      });
    }
  }

  mergeRowsWithActual(actual: DemandTableRow) {
    this.data = actual?.data;
    this.children = actual?.children;
  }

  compare(t: DemandTableRow) {
    if (!t || !t.data) {
      this.diff = [];
      return;
    }
    // diff is absolute difference (not percentage difference)
    this.diff = this.data.map((d1, i) => {
      // The comparison absolute difference vs selected scenario (or actual/last year/current) is to show [(Selected Scenario) - (non Selected Scenario)]
      // Example: Selected Scenario is 110 and Current is 100 >> then it should show 100 and +10
      // In this code, d2 is Selected Scenario, d1 is non Selected Scenario
      const d2 = t.data[i] || 0;
      const v = d2 - (d1 || 0);
      return v;
    });
    const tcMap = new Map(t.children.map(tc => [tc.key, tc]));
    for (let i = 0; i < this.children.length; i++) {
      const c = this.children[i];
      const tc = tcMap.get(c.key);
      if (tc) {
        c.compare(tc);
      }
    }
  }

  sort() {
    this.children.sort((a, b) => a.label < b.label ? -1 : 1);
    this.children.forEach(t => t.sort());
  }

  sum(start: number, end: number) {
    let s = 0;
    for (let i = start; i <= end; i++) {
      s += (this.data[i] || 0);
    }
    return s;
  }

  average(start: number, end: number): number {
    let sum = 0;
    let count = 0;

    for (let i = start; i <= end; i++) {
      sum += (this.data[i] || 0);
      count++;
    }

    if (count === 0) {
      return 0; // Avoid dividing by zero
    }

    return sum / count;
  }

  getLastMonthValue(end: number): number {
    return this.data[end] || 0;
  }

  numOfMonths(label: string) {
    if (label.includes("Q")) {
      return 3;
    } else if (label.includes("H")) {
      return 6;
    }
    return 12;
  }

  wholeRangeHasNoData(label: string, start: number, end: number) {
    const numOfMonths = this.numOfMonths(label);
    if (end - start === numOfMonths - 1) {
      let nullCounter = 0;
      for (let i = start; i <= end; i++) {
        if (this.data[i] == null) {
          nullCounter++;
        }
      }

      return nullCounter === numOfMonths
    }

    return false;
  }

  calculateYTDValue(a: AddSumArg, midIndex: number, op: OpFunc, lastMonthOp: LastMonthFunc | undefined = undefined) {
    if (midIndex <= a.start) {
      return 0;
    } else if (midIndex > a.start && midIndex <= a.end) {
      return lastMonthOp ? lastMonthOp(midIndex - 1) : op(a.start, midIndex - 1);
    } else {
      return lastMonthOp ? lastMonthOp(a.end) : op(a.start, a.end);
    }
  }

  calculateYTGValue(a: AddSumArg, midIndex: number, op: OpFunc, lastMonthOp: LastMonthFunc | undefined = undefined) {
    if (midIndex < a.start) {
      return lastMonthOp ? lastMonthOp(a.end) : op(a.start, a.end);
    } else if (midIndex >= a.start && midIndex <= a.end) {
      return lastMonthOp ? lastMonthOp(a.end) : op(midIndex, a.end);
    } else {
      return 0;
    }
  }

  calculateVarValue(a: AddSumArg, midIndex: number, op: OpFunc, lastMonthOp: LastMonthFunc | undefined = undefined) {
    const ytd = this.calculateYTDValue(a, midIndex, op, lastMonthOp);
    const ytg = this.calculateYTGValue(a, midIndex, op, lastMonthOp);
    return ((ytg != undefined) && (ytd != undefined)) ? (ytg - ytd) : undefined;
  }

  calculateVarPercentValue(a: AddSumArg, midIndex: number, op: OpFunc, lastMonthOp: LastMonthFunc | undefined = undefined) {
    const ytd = this.calculateYTDValue(a, midIndex, op, lastMonthOp);
    const variance = this.calculateVarValue(a, midIndex, op, lastMonthOp);
    return ((variance != undefined) && (ytd != undefined)) ? (variance / ytd) : undefined;
  }

  calculateValue(a: AddSumArg, midIndex: number, selectedAtomicKpi?: string) {
    if (this.wholeRangeHasNoData(a.label, a.start, a.end)) {
      // This function should return null instead of undefined
      // to avoid conflicts with the condition result[i + offset] !== undefined
      // in the insertToArray function.
      return null;
    }

    let calculatedValue: number | undefined = undefined;
    const isLabel = (analytic: string) => checkAnalyticType(a.label, analytic);
    const isPercentKpi = PERCENT_KPIS.includes(selectedAtomicKpi || '');
    const isMonthEndKpi = MONTH_END_KPIS.includes(selectedAtomicKpi || '');
    const opFunc = isPercentKpi ? this.average.bind(this) : this.sum.bind(this);
    const lastMonthOp = isMonthEndKpi ? this.getLastMonthValue.bind(this) : undefined; 
    
    if (isLabel("YTD")) {
      calculatedValue = this.calculateYTDValue(a, midIndex, opFunc, lastMonthOp);
    } else if (isLabel("YTG")) {
      calculatedValue = this.calculateYTGValue(a, midIndex, opFunc, lastMonthOp);
    } else if (isLabel("Avg YTD")) {
      calculatedValue = this.calculateYTDValue(a, midIndex, this.average.bind(this));
    } else if (isLabel("Avg YTG")) {
      calculatedValue = this.calculateYTGValue(a, midIndex, this.average.bind(this));
    } else if (isLabel("Var")) {
      calculatedValue = this.calculateVarValue(a, midIndex, opFunc, lastMonthOp);
    } else if (isLabel("Avg Var")) {
      calculatedValue = this.calculateVarValue(a, midIndex, this.average.bind(this));
    } else if (isLabel("Var%")) {
      calculatedValue = this.calculateVarPercentValue(a, midIndex, opFunc, lastMonthOp);
    } else if (isLabel("Avg Var%")) {
      calculatedValue = this.calculateVarPercentValue(a, midIndex, this.average.bind(this));
    } else if (a.label.includes("Avg")) {
      calculatedValue = this.average(a.start, a.end);
    } else {
      const restCaseValue = isPercentKpi ? this.average(a.start, a.end) : this.sum(a.start, a.end);
      calculatedValue = isMonthEndKpi ? this.getLastMonthValue(a.end) : restCaseValue;
    }
    return calculatedValue;
  };

  addAnalytics(addAnalyticsParams: AddAnalyticsParams) {
    const { args, key, actualEndDate, futurePlanStartDate, futurePlanEndDate, dateColumns, selectedAtomicKpi } = addAnalyticsParams;
    const actualEndDateIndex = dateColumns.findIndex(date => endOfMonth(new Date(date)) > actualEndDate);
    const futurePlanStartDateIndex = dateColumns.findIndex(date => startOfMonth(new Date(date)) >= futurePlanStartDate);

    const toAdd = new Map(args.map(a => {
      const actualPlanKeys = [
        "actual",
        ...Array.from({ length: 5 }, (_, i) => `actual-${i + 1}`),
        "committed",
        "baseDemand"
      ];
      const midIndex = actualPlanKeys.includes(key) ? actualEndDateIndex : futurePlanStartDateIndex;

      return [
        a.target,
        this.calculateValue(a, midIndex, selectedAtomicKpi)
      ]
    }));
    this.data = insertToArray(this.data, toAdd);
    this.children.forEach(t => t.addAnalytics(addAnalyticsParams));
  }
}

// insert the values from toAdd to the given index
function insertToArray(arr: any[], toAdd: Map<number, any>) {
  let offset = 0;
  const result: any[] = [];
  for (const [k, v] of toAdd.entries()) {
    result[k] = v;
  }
  for (let i = 0; i < arr.length; i++) {
    const v = arr[i];
    // skip existing entries
    while (result[i + offset] !== undefined) {
      offset++;
    }
    result[i + offset] = v;
  }
  return result;
}

export class DemandTableData {
  constructor(data: IDemandChartResponse) {
    this.columns = data.columns || [];
    this.tree = data.tree.map(t => new DemandTableRow(t));
  }

  mergeHistoricalData() {
    const foundActualTree = this.tree.find(t => t.key === "actual");
    const foundCurrentTree = this.tree.find(t => t.key === "current");
    // Should deep copy to avoid unexpected data mutation during the merging process
    const actual = foundActualTree ? JSON.parse(JSON.stringify(foundActualTree)) : null;
    const current = foundCurrentTree ? JSON.parse(JSON.stringify(foundCurrentTree)) : null;
    if (!actual || !current) {
      return;
    }
    const shouldMerge = (key: string) => key === "current" || key?.length > 8;
    this.tree.forEach((t, i) => {
      if (!shouldMerge(t.key)) { return; }
      const endColumn = t.data.findIndex(d => d != null);
      if (endColumn > -1) {
        const lastActualIndex = actual.data.findIndex(d => d == null) - 1;
        t.mergeWith(actual, current, 0, endColumn, lastActualIndex);
      } else {
        // This case is where there are no real data in t (endColumn === -1)
        // To avoid a parent row with no children, we should also copy the actual children because t doesn't have children
        t.mergeRowsWithActual(this.tree.find(t => t.key === "actual") as DemandTableRow);
      }
    });
  }

  removeTableRow(key: string) {
    this.tree = this.tree.filter(t => t.key !== key);
  }

  // only accept ISO date format: YYYY-MM-DD
  dateToQuarter(date: string, fiscalYearStartMonth: number = 1) {
    const adjustedMonth = this.dateToMonth(date, fiscalYearStartMonth);
    const quarter = Math.ceil(adjustedMonth / 3);
    const adjustedYear = this.dateToYear(date, fiscalYearStartMonth);

    return `${adjustedYear} Q${quarter}`;
  }

  dateToHalfYear(date: string, fiscalYearStartMonth: number = 1) {
    const adjustedMonth = this.dateToMonth(date, fiscalYearStartMonth);
    const half = Math.ceil(adjustedMonth / 6);
    const adjustedYear = this.dateToYear(date, fiscalYearStartMonth);

    return `${adjustedYear} H${half}`;
  }

  dateToMonth(date: string, fiscalYearStartMonth: number = 1) {
    const month = parseInt(date.substring(5, 7), 10);
    // Adjust the month based on the fiscal year start month
    const adjustedMonth = (month - fiscalYearStartMonth + 12) % 12 + 1;

    return adjustedMonth;
  }

  dateToYear(date: string, fiscalYearStartMonth: number = 1) {
    const year = parseInt(date.substring(0, 4), 10);
    const month = parseInt(date.substring(5, 7), 10);
    // Adjust the year based on the fiscal year start month
    const adjustedYear = month >= fiscalYearStartMonth ? year : year - 1;

    return adjustedYear;
  }

  dateToYearY(date: string, fiscalYearStartMonth: number = 1) {
    return `${this.dateToYear(date, fiscalYearStartMonth)}Y`;
  }

  hasAnalytic(analyticGroupings: string[], analytic: AnalyticsTypes) {
    return analyticGroupings.includes(analytic)
  }

  hasAnalytics(analyticGroupings: string[]) {
    const hasQuarterTotal = this.hasAnalytic(analyticGroupings, AnalyticsTypes.QUARTER_TOTAL);
    const hasQuarterAvg = this.hasAnalytic(analyticGroupings, AnalyticsTypes.QUARTER_AVERAGE);
    const hasHalfYearTotal = this.hasAnalytic(analyticGroupings, AnalyticsTypes.HALF_YEAR_TOTAL);
    const hasHalfYearAvg = this.hasAnalytic(analyticGroupings, AnalyticsTypes.HALF_YEAR_AVERAGE);
    const hasFyTotal = this.hasAnalytic(analyticGroupings, AnalyticsTypes.FY_TOTAL);
    const hasFyAvg = this.hasAnalytic(analyticGroupings, AnalyticsTypes.FY_AVERAGE);
    const hasYTD = this.hasAnalytic(analyticGroupings, AnalyticsTypes.YTD_TOTAL);
    const hasAvgYTD = this.hasAnalytic(analyticGroupings, AnalyticsTypes.YTD_AVERAGE);
    const hasYTG = this.hasAnalytic(analyticGroupings, AnalyticsTypes.YTG_TOTAL);
    const hasVar = this.hasAnalytic(analyticGroupings, AnalyticsTypes.DIFF_YTD_YTG);
    const hasVarPercent = this.hasAnalytic(analyticGroupings, AnalyticsTypes.PERCENTAGE_DIFF_YTD_YTG);
    const hasAvgYTG = this.hasAnalytic(analyticGroupings, AnalyticsTypes.YTG_AVERAGE);
    const hasAvgVar = this.hasAnalytic(analyticGroupings, AnalyticsTypes.DIFF_AVG_YTD_YTG);
    const hasAvgVarPercent = this.hasAnalytic(analyticGroupings, AnalyticsTypes.PERCENTAGE_DIFF_AVG_YTD_YTG);

    return {
      hasQuarterTotal,
      hasQuarterAvg,
      hasHalfYearTotal,
      hasHalfYearAvg,
      hasFyTotal,
      hasFyAvg,
      hasYTD,
      hasAvgYTD,
      hasYTG,
      hasVar,
      hasVarPercent,
      hasAvgYTG,
      hasAvgVar,
      hasAvgVarPercent,
    }
  }

  createAnalyticKeys(analyticGroupings: string[], column: string, fiscalYearStartMonth: number = 1) {
    const keys: string[] = [];
    const {
      hasQuarterTotal,
      hasQuarterAvg,
      hasHalfYearTotal,
      hasHalfYearAvg,
      hasFyTotal,
      hasFyAvg,
      hasYTD,
      hasAvgYTD,
      hasYTG,
      hasVar,
      hasVarPercent,
      hasAvgYTG,
      hasAvgVar,
      hasAvgVarPercent,
    } = this.hasAnalytics(analyticGroupings);

    if (hasQuarterTotal) {
      keys.push(this.dateToQuarter(column, fiscalYearStartMonth));
    }
    if (hasQuarterAvg) {
      keys.push(`${this.dateToQuarter(column, fiscalYearStartMonth)} Avg`);
    }
    if (hasHalfYearTotal) {
      keys.push(this.dateToHalfYear(column, fiscalYearStartMonth));
    }
    if (hasHalfYearAvg) {
      keys.push(`${this.dateToHalfYear(column, fiscalYearStartMonth)} Avg`);
    }
    if (hasFyTotal) {
      keys.push(this.dateToYearY(column, fiscalYearStartMonth));
    }
    if (hasFyAvg) {
      keys.push(`${this.dateToYearY(column, fiscalYearStartMonth)} Avg`);
    }
    if (hasYTD) {
      keys.push(`${this.dateToYear(column, fiscalYearStartMonth)} YTD`);
    }
    if (hasAvgYTD) {
      keys.push(`${this.dateToYear(column, fiscalYearStartMonth)} Avg YTD`);
    }
    if (hasYTG) {
      keys.push(`${this.dateToYear(column, fiscalYearStartMonth)} YTG`);
    }
    if (hasVar) {
      keys.push(`${this.dateToYear(column, fiscalYearStartMonth)} Var`);
    }
    if (hasVarPercent) {
      keys.push(`${this.dateToYear(column, fiscalYearStartMonth)} Var%`);
    }
    if (hasAvgYTG) {
      keys.push(`${this.dateToYear(column, fiscalYearStartMonth)} Avg YTG`);
    }
    if (hasAvgVar) {
      keys.push(`${this.dateToYear(column, fiscalYearStartMonth)} Avg Var`);
    }
    if (hasAvgVarPercent) {
      keys.push(`${this.dateToYear(column, fiscalYearStartMonth)} Avg Var%`);
    }

    return keys;
  }

  createIndexMap(analyticGroupings: string[], fiscalYearStartMonth: number = 1) {
    const indexMap = new Map<string, number[]>();

    this.columns.forEach((c, i) => {
      const keys = this.createAnalyticKeys(analyticGroupings, c, fiscalYearStartMonth);

      for (const key of keys) {
        if (!indexMap.has(key)) {
          indexMap.set(key, []);
        }
        indexMap.get(key)?.push(i);
      }
    });

    return indexMap;
  }

  sortAnalyticTitles(a: string, b: string, analyticGroupings: string[]) {
    const analyticsTypesOrder = analyticsTypesOrderCreator(analyticGroupings);
    
    // Extract the year and type (e.g., H2, Q4, Avg)
    const [yearA, typeA] = splitAnalyticTitle(a);
    const [yearB, typeB] = splitAnalyticTitle(b);

    // Compare years
    if (yearA !== yearB) {
      return yearA - yearB;
    }
  
    // Compare types based on their order
    const typeIndexA = analyticsTypesOrder.indexOf(typeA);
    const typeIndexB = analyticsTypesOrder.indexOf(typeB);
  
    return typeIndexA - typeIndexB;
  };

  getIndexMapKeys(indexMap: Map<string, number[]>, analyticGroupings: string[], fiscalYearStartMonth: number = 1) {
    return Array.from(indexMap.keys()).sort((a, b) => {
      return this.sortAnalyticTitles(a, b, analyticGroupings);
    });
  }

  createAfterDecemberIndexes() {
    const afterDecemberIndexes = this.columns.reduce((indexes: number[], date, index: number) => {
      if (date.includes('-12-')) {
        indexes.push(index + 1);
      }
      return indexes;
    }, []);

    if (!this.columns[this.columns.length - 1].includes("-12-")) {
      afterDecemberIndexes.push(this.columns.length);
    }

    return afterDecemberIndexes;
  }

  createAfterFiscalYearIndexes(fiscalYearStartMonth: number = 1): number[] {
    const afterFiscalYearIndexes = this.columns.reduce((indexes: number[], date, index: number) => {
      const month = parseInt(date.substring(5, 7), 10);
      // Calculate the fiscal year end month
      const fiscalYearEndMonth = (fiscalYearStartMonth + 11) % 12 || 12;

      // Check if the current month is the end of the fiscal year
      if (month === fiscalYearEndMonth) {
        indexes.push(index + 1);
      }
      return indexes;
    }, []);

    const lastDateMonth = parseInt(this.columns[this.columns.length - 1].substring(5, 7), 10);
    const fiscalYearEndMonth = (fiscalYearStartMonth + 11) % 12 || 12;
    
    if (lastDateMonth !== fiscalYearEndMonth) {
      afterFiscalYearIndexes.push(this.columns.length);
    }

    return afterFiscalYearIndexes;
  }

  handleAnalyticCase(analyticCaseParams: AnalyticCaseParams) {
    const {
      numOfMonths,
      hasArgument,
      argumentArray,
      counterObj,
      afterFiscalYearPointer,
      label,
      start,
      end,
      fiscalYearStartMonth,
    } = analyticCaseParams;

    // Do not count this if it has less than [numOfMonths] months
    if (end - start < numOfMonths - 1) {
      return;
    }
    if (hasArgument) {
      const afterFiscalYearIndexes = this.createAfterFiscalYearIndexes(fiscalYearStartMonth);
      const target = afterFiscalYearIndexes[afterFiscalYearPointer] + counterObj.value;
      counterObj.value++;
      argumentArray.push({
        start,
        end,
        target,
        label,
      });
    }
  }

  private buildSumArgs(analyticGroupings: string[], fiscalYearStartMonth: number = 1) {
    const indexMap = this.createIndexMap(analyticGroupings, fiscalYearStartMonth);
    const args: AddSumArg[] = [];
    const quarterArgs: AddSumArg[] = [];
    const yearArgs: AddSumArg[] = [];
    const halfYearArgs: AddSumArg[] = [];
    const quarterAvgArgs: AddSumArg[] = [];
    const yearAvgArgs: AddSumArg[] = [];
    const halfYearAvgArgs: AddSumArg[] = [];
    const ytdArgs: AddSumArg[] = [];
    const ytdAvgArgs: AddSumArg[] = [];
    const ytgArgs: AddSumArg[] = [];
    const varArgs: AddSumArg[] = [];
    const varPercentArgs: AddSumArg[] = [];
    const ytgAvgArgs: AddSumArg[] = [];
    const varAvgArgs: AddSumArg[] = [];
    const varAvgPercentArgs: AddSumArg[] = [];

    const {
      hasQuarterTotal,
      hasQuarterAvg,
      hasHalfYearTotal,
      hasHalfYearAvg,
      hasFyTotal,
      hasFyAvg,
      hasYTD,
      hasAvgYTD,
      hasYTG,
      hasVar,
      hasVarPercent,
      hasAvgYTG,
      hasAvgVar,
      hasAvgVarPercent,
    } = this.hasAnalytics(analyticGroupings);

    const keys = this.getIndexMapKeys(indexMap, analyticGroupings, fiscalYearStartMonth);

    const [year0] = splitAnalyticTitle(keys[0]);
    let previousYearPointer = year0;
    const counter = { value: 0 };
    let afterFiscalYearPointer = 0;

    const analyticTypes = [
      { key: "Q", condition: (k) => checkAnalyticType(k, "Q"), numOfMonths: 3, hasArgument: hasQuarterTotal, argumentArray: quarterArgs },
      { key: "Q Avg", condition: (k) => checkAnalyticType(k, "Q Avg"), numOfMonths: 3, hasArgument: hasQuarterAvg, argumentArray: quarterAvgArgs },
      { key: "H", condition: (k) => checkAnalyticType(k, "H"), numOfMonths: 6, hasArgument: hasHalfYearTotal, argumentArray: halfYearArgs },
      { key: "H Avg", condition: (k) => checkAnalyticType(k, "H Avg"), numOfMonths: 6, hasArgument: hasHalfYearAvg, argumentArray: halfYearAvgArgs },
      { key: "Y", condition: (k) => checkAnalyticType(k, "Y"), numOfMonths: 12, hasArgument: hasFyTotal, argumentArray: yearArgs },
      { key: "Y Avg", condition: (k) => checkAnalyticType(k, "Y Avg"), numOfMonths: 12, hasArgument: hasFyAvg, argumentArray: yearAvgArgs },
      { key: "YTD", condition: (k) => checkAnalyticType(k, "YTD"), numOfMonths: 12, hasArgument: hasYTD, argumentArray: ytdArgs },
      { key: "Avg YTD", condition: (k) => checkAnalyticType(k, "Avg YTD"), numOfMonths: 12, hasArgument: hasAvgYTD, argumentArray: ytdAvgArgs },
      { key: "YTG", condition: (k) => checkAnalyticType(k, "YTG"), numOfMonths: 12, hasArgument: hasYTG, argumentArray: ytgArgs },
      { key: "Var", condition: (k) => checkAnalyticType(k, "Var"), numOfMonths: 12, hasArgument: hasVar, argumentArray: varArgs },
      { key: "Var%", condition: (k) => checkAnalyticType(k, "Var%"), numOfMonths: 12, hasArgument: hasVarPercent, argumentArray: varPercentArgs },
      { key: "Avg YTG", condition: (k) => checkAnalyticType(k, "Avg YTG"), numOfMonths: 12, hasArgument: hasAvgYTG, argumentArray: ytgAvgArgs },
      { key: "Avg Var", condition: (k) => checkAnalyticType(k, "Avg Var"), numOfMonths: 12, hasArgument: hasAvgVar, argumentArray: varAvgArgs },
      { key: "Avg Var%", condition: (k) => checkAnalyticType(k, "Avg Var%"), numOfMonths: 12, hasArgument: hasAvgVarPercent, argumentArray: varAvgPercentArgs },
    ];

    // Main loop to generate args
    for (const k of keys) {
      const v = indexMap.get(k);
      if (!v) { continue; }

      const start = v[0];
      const end = v[v.length - 1];
      const [yearK] = splitAnalyticTitle(k);
      const currentYearPointer = yearK;

      if (previousYearPointer < currentYearPointer) {
        afterFiscalYearPointer++;
        previousYearPointer = currentYearPointer;
      }

      for (const type of analyticTypes) {
        if (type.condition(k)) {
          this.handleAnalyticCase({
            numOfMonths: type.numOfMonths,
            hasArgument: type.hasArgument,
            argumentArray: type.argumentArray,
            counterObj: counter,
            afterFiscalYearPointer,
            label: k,
            start,
            end,
            fiscalYearStartMonth,
          });
        }
      }
    }

    for (const type of analyticTypes) {
      args.push(...type.argumentArray);
    }

    return args;
  }

  addAnalyticColumns(analyticColumnParams: AnalyticColumnParams) {
    const { analyticGroupings, actualEndDate, futurePlanStartDate, futurePlanEndDate, selectedAtomicKpi, fiscalYearStartMonth } = analyticColumnParams;
    const args = this.buildSumArgs(analyticGroupings, fiscalYearStartMonth);
    this.tree.forEach(t => {
      const addAnalyticsParams = { args, key: t.key, actualEndDate, futurePlanStartDate, futurePlanEndDate, dateColumns: this.columns, selectedAtomicKpi };

      return t.addAnalytics(addAnalyticsParams)
    });
    let newColumns = new Map(args.map(a => [a.target, a.label]));
    this.columns = insertToArray(this.columns, newColumns);
    this.fyColumns = formatFiscalYearForColumnTitles(this.columns, fiscalYearStartMonth);
  }

  compare(tree: DemandTableRow) {
    this.tree.forEach(t => t.compare(tree));
  }

  sort() {
    this.tree.forEach(t => t.sort());
  }

  public columns: string[] = [];
  public fyColumns: string[] = [];
  public tree: DemandTableRow[] = [];
  public y2yData: Y2YData | undefined = undefined;
}

export const select_combinedDemandsTableDataWithExtraColumns = createSelector(
  selectFeature,
  select_combinedDemandsChartData,
  select_highlightedScenario,
  select_selectedWorkspace,
  select_selectedPlan,
  select_latestActual,
  select_displayingScenarios,
  (state, data, highlightedScenario, workspace, selectedPlan, latestActual, displayingScenarios) => {
    let table = new DemandTableData(data);
    if (data.columns.length < 1) {
      return table;
    }

    table.sort();
    table.mergeHistoricalData();

    const scenarios = displayingScenarios.map(pick(['id', 'name']));
    const initialHasActualId = scenarios.some((s) => s.id === 'actual');
    const initialHasCurrentId = scenarios.some((s) => s.id === 'current');
    // Remove actual or current from the tree if they aren't present in displayingScenarios,
    // as they are not intended to be displayed in the demand table.
    if (!initialHasActualId) {
      table.removeTableRow('actual');
    }
    if (!initialHasCurrentId) {
      table.removeTableRow('current');
    }

    const fiscalYearStartMonth = workspace.settings?.fiscalYearStartMonth || 1;
    const analyticColumnParams = {
      analyticGroupings: state?.analyticGroupings,
      actualEndDate: endOfMonth(new Date(latestActual?.actualEndDate)),
      futurePlanStartDate: startOfMonth(new Date(selectedPlan?.futurePlanStartDate || '')),
      futurePlanEndDate: endOfMonth(new Date(selectedPlan?.futurePlanEndDate || '')),
      fiscalYearStartMonth,
    };
    table.addAnalyticColumns(analyticColumnParams);
    // Should calculate y2yData after addAnalyticColumns() to get full columns with analytics
    table.y2yData = getY2YData(table.columns, state?.analyticGroupings, fiscalYearStartMonth);

    if (highlightedScenario) {
      const key = highlightedScenario.id;
      const highlightedRow = table.tree.find(t => t.key == key);
      if (highlightedRow) {
        table.compare(highlightedRow);
      }
    }
    
    table.tree.forEach(row => {
      // calculate y2y comparison for all table parent rows
      // pass row by reference, therefore mutate the table's row directly
      calculateY2YDiff(row, table.y2yData!);
    })

    return table;
  }
)

export const select_formatByDateAggregation = createSelector(
  select_dateAggregation,
  select_selectedWorkspace,
  (dateAggregation, workspace) => formatByDateAggregationGen(dateAggregation, workspace?.settings?.fiscalYearStartMonth),
);

export const select_formatByDateAggregationKPITracker = createSelector(
  select_selectedWorkspace,
  (workspace) => {
    return formatByDateAggregationGen(DateAggregationOption.MONTH, workspace?.settings?.fiscalYearStartMonth)
  }
);

export const select_demandChartOptions = createSelector(
  select_combinedDemandsChartData,
  select_selectedWorkspace,
  select_selectedPlan,
  select_rawDemands,
  select_displayingScenarios,
  select_highlightedScenario,
  select_dateAggregation,
  (
    combinedDemandsChartData,
    workspace,
    selectedPlan,
    rawDemands,
    displayingScenarios,
    highlightedScenario,
    dateAggregation
  ): EChartsOption => {
    const marks = calculateDataArea(rawDemands);
    const fiscalYearStartMonth = workspace ? workspace.settings?.fiscalYearStartMonth : 1
    const colFormatter = formatByDateAggregationGen(dateAggregation, fiscalYearStartMonth);
    const scenarios = removeDuplicates(displayingScenarios.map(pick(['id', 'name', 'color'])));
    const hasScenarios = scenarios.length > 0;

    // If Actual is unloaded, still display its chart
    const initialHasActualId = scenarios.some((s) => s.id === 'actual');
    if (!initialHasActualId && hasScenarios) {
      scenarios.push({ id: 'actual', name: 'Actual', color: "#979797" });
    }

    const { hasGapBeforeStartOfFuture, hasGapBetweenActualCurrent } = combinedDemandsChartData.fillingData || {};
    // If Current is unloaded and there is a range that Actual has no data to fill Future Plan
    // Then, still display its chart
    const initialHasCurrentId = scenarios.some((s) => s.id === 'current');
    if (!initialHasCurrentId && hasScenarios && (hasGapBeforeStartOfFuture || hasGapBetweenActualCurrent)) {
      scenarios.push({ id: 'current', name: 'Last Plan', color: "#AD3781" });
    }

    // const scenarioLegend = generateShortedScenarioLegend(sce)
    // const generateShorterKpiName(kpiName: string): string {
    //   if (kpiName.concat('\n')) {
    //     kpiName = kpiName.replace(/\n/g, ' ');
    //   }
    //   return kpiName.match(/.{1,20}/g)?.[0] + '...';
    // }

    // const a = takeRowDataById('actual', combinedDemandsChartData.rows)

    const chartSeries = scenarios.map(({ id }) => ({
      id: id,
      name: id,
      markArea: id !== 'current' && marks[id] ? marks[id] : marks.future || {}, // Not show Current's area
      emphasis: { disabled: true },
      data: takeRowDataById(id, combinedDemandsChartData.rows),
      ...DEFAULT_ECHARTS_SERIES_OPTIONS,
      legendHoverLink: true,
      lineStyle: { width: id === highlightedScenario?.id ? 4 : 1 },
      // if only has a point, will appear mark point
      showSymbol: combinedDemandsChartData.columns.length > 1 ? false : true,
      type: 'line'
    }))

    const trendLines = scenarios.map(({ id, color }, index) => {
      return {
        id: 'trend_' + id,
        name: 'trend_' + id,
        type: 'line',
        legendHoverLink: false,
        showSymbol: false,
        lineStyle: {
          type: 'dashed',
          width: 1,
          color,
          opacity: 0.6
        },
        tooltip: {
          trigger: 'none', // This line will hide 'Series 2' from the tooltip
        },
        data: calculateTrendLine(id, combinedDemandsChartData.columns, takeRowDataById(id, combinedDemandsChartData.rows))
      }
    })

    const totalMap = computeTotalLookupByNames(combinedDemandsChartData, scenarios);
    const acceptedScale = workspace ? workspace.settings?.acceptedScale : []
    // we will overwrite echarts option
    return <EChartsOption>{
      tooltip: {
        formatter: seriesPairFormatterFactory_v2(highlightedScenario?.id, workspace, false, false, scenarios, acceptedScale, colFormatter),
        trigger: 'axis'
      },
      title: {
        text: 'Demand chart',
        textStyle: { fontWeight: 500, fontFamily: 'Roboto', fontSize: 14, color: '#00355C' },
      },
      grid: { bottom: '40', containLabel: true, left: 200, right: 20 },
      legend: {
        data: [
          ...scenarios.map(i => ({
            name: i.id,
            icon: 'roundRect'
          })),
          ...scenarios.map(i => ({
            name: 'trend_' + i.id,
            // This is trick to hide trendline in legend
            icon: 'none',
            textStyle: {
              color: '#fff',
              width: 0,
              overflow: 'truncate'
            },
            inactiveColor: '#fff'
            // This is trick to hide trendline in legend
          }))
        ],
        formatter: (id) => {
          const matchScenario = scenarios.find(s => s?.id == id)

          if (matchScenario) {
            return `${generateShortedScenarioLegend(matchScenario.name)}\nTotal: ${transformInputToKpiFormat(totalMap[matchScenario.name], '-', acceptedScale)}`;
          }

          return id
        },
        itemHeight: 2,
        left: 'left',
        orient: 'vertical',
        top: 50
      },
      xAxis: {
        data: combinedDemandsChartData.columns,
        axisLabel: { formatter: formatByDateAggregationGen(dateAggregation, fiscalYearStartMonth) },
        axisTick: {
          alignWithLabel: true
        }
      },
      yAxis: {
        axisLabel: {
          formatter: (s) => {
            return transformInputToKpiFormat(s, '-', acceptedScale)
          }
        },
        splitLine: { show: false },
      },
      
      color: scenarios.map((s) => s.color || '#0C80EB'),
      series: [
        ...chartSeries,
        ...trendLines
      ],
      dataZoom: [
        {
          type: 'slider',
          xAxisIndex: 0,
          filterMode: 'none',
          bottom: 5
        },
        {
          type: 'slider',
          yAxisIndex: 0,
          filterMode: 'none'
        }
      ]
    };
  },
);

/** Compose the param to load Demand Chart data */
export const select_params_loadDemandChartData = createSelector(
  select_selectedPlan,
  select_selectedSegment,
  select_selectedDateRange,
  select_selectedWorkspace,
  select_demandGroupings,
  select_dateAggregation,
  select_uom,
  (
    selectedPlan,
    selectedSegment,
    selectedDateRange,
    selectedWorkspace,
    demandGroupings,
    dateAggregation,
    uom
  ): IGetDemandsChartDataParams | undefined => {
    if (!selectedPlan) return;

    const forecastMethod = selectedPlan?.forecastMethod;
    const forecastCollection = selectedPlan?.forecastCollection || selectedWorkspace?.forecastCollection[forecastMethod];

    return <IGetDemandsChartDataParams>{
      planId: selectedPlan.id,
      isActual: selectedPlan.flags?.includes(PlanFlag.ACTUAL),
      interval: dateAggregation,
      segment: selectedSegment,
      dateRange: selectedDateRange?.start && {
        start: selectedDateRange?.start,
        end: selectedDateRange?.end
      },
      groupingColumns: propsToColumns(demandGroupings),
      workspaceId: selectedPlan.workspace.id,
      forecastCollection,
      uom,
    };
  },
);

/** Compose the param to load Demand Impact Chart data */
export const select_params_loadDemandImpactsChartData = createSelector(
  select_selectedPlan,
  select_params_loadDemandChartData,
  select_displayingScenarios,
  (selectedPlan, params, displayingScenarios) => {
    if (!params) return;

    const scenariosToExclude = ['current'];

    if (selectedPlan?.flags?.includes(PlanFlag.ACTUAL)) {
      const displayedScenarioIDs = displayingScenarios
        .filter(s => !scenariosToExclude.includes(s.id)) // getDemandImpacts doesn't work with 'current' scenarioId in params, should fix?
        .filter(s => !s.id?.includes('actual-'))
        .map((s) => s.blankScenario ? { id: s.id, events: s.events } : s.id)

      return <IGetDemandImpactsChartDataParams>{
        ...params,
        scenarios: [...displayedScenarioIDs],
      };
    }

    return <IGetDemandImpactsChartDataParams>{
      ...params,
      scenarios: [
        ...displayingScenarios
          .filter(s => !scenariosToExclude.includes(s.id)) // getDemandImpacts doesn't work with 'current' scenarioId in params, should fix?
          .filter(s => !s.id?.includes('actual-'))
          .map((s) =>
            s.blankScenario ? { id: s.id, events: s.events, planId: selectedPlan?.id } : s.id,
          )
      ],
    };
  },
);
