import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subject, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { en_US, NzI18nService } from 'ng-zorro-antd/i18n';
import {
  NbAuthOAuth2JWTToken,
  NbTokenLocalStorage,
} from '@nebular/auth';

import { AnalyticsService } from './@core/utils';
import { environment } from '@/environments/environment';
import { AbilityService } from '@/app/services/ability.service';
import { CrdtService, CursorProps, EditingSitScenario, LocalUser, Position, RemoteUser } from './@core/services/crdt.service';
import { UserStore } from './@core/stores/user.store';
import { User } from './@core/interfaces/common/users';
import { select_editingScenario } from '@/store/pages/layout/layout.baseSelectors';
import { Scenario, ScenarioWithTaskStatus } from './@core/interfaces/business/scenario';
import { select_editingEvent, select_editingVersion } from '@/store/event/event.selectors';
import { Event, EventVersion } from './@core/interfaces/business/event';
import { select_selectedWorkspace } from '@/store/workspace/workspace.selectors';
import { Workspace } from './@core/interfaces/common/workspace';
import { select_editingSitScenarios, select_sitSimulatingScenario, select_sitValidatingScenario } from '@/store/pages/sit/sit.selectors';
import { select_displayingScenariosWithTaskStatus } from '@/store/scenario/scenario.selectors';

@Component({
  selector: 'cel-app',
  template: `
    <router-outlet></router-outlet>
    <!--
    <div *ngFor="let user of remoteUsers; let i = index">
      <cel-cursor [index]="i" [name]="user.name" [position]="user.cursor"></cel-cursor>
    </div>
    -->
  `,
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>();
  moveCursor: ((cursorProps: CursorProps) => void) | undefined = undefined;
  private crdtCleanup!: () => void;
  localUser: LocalUser | undefined = undefined;
  remoteUsers: RemoteUser[] = [];
  currentUser?: User;
  editingScenario$ = this.store.select(select_editingScenario);
  editingScenario: Scenario | undefined = undefined;
  editingEvent$ = this.store.select(select_editingEvent);
  editingEvent: Event | undefined = undefined;
  editingVersion$ = this.store.select(select_editingVersion);
  editingVersion: EventVersion | undefined = undefined;
  selectedWorkspace$ = this.store.select(select_selectedWorkspace);
  selectedWorkspace: Workspace | undefined = undefined;
  editingSitScenarios$ = this.store.select(select_editingSitScenarios);
  editingSitScenarios: EditingSitScenario[] = [];
  selectedScenarios$ = this.store.select(select_displayingScenariosWithTaskStatus);
  selectedScenarios: ScenarioWithTaskStatus[] = [];
  sitValidatingScenario$ = this.store.select(select_sitValidatingScenario);
  sitValidatingScenario: string = '';
  sitSimulatingScenario$ = this.store.select(select_sitSimulatingScenario);
  sitSimulatingScenario: string = '';
  simulatingScenarioIds: string[] = [];

  constructor(
    private readonly analytics: AnalyticsService,
    private readonly titleService: Title,
    private readonly i18n: NzI18nService,
    private readonly nbTokenStorageService: NbTokenLocalStorage,
    private readonly abilityService: AbilityService,
    private readonly crdtService: CrdtService,
    private readonly userStore: UserStore,
    private readonly store: Store,
  ) {}

  ngOnInit(): void {
    this.i18n.setLocale(en_US);
    this.titleService.setTitle(`SIMCEL`);
    this.analytics.trackPageViews();

    const token = this.nbTokenStorageService.get() as NbAuthOAuth2JWTToken

    if (token.isValid()) {
      const accessTokenPayload = token.getAccessTokenPayload();

      this.abilityService.defineAbilitiesFor(accessTokenPayload.role);
    }

    console.log(
      '🗿 You are running %c%s',
      'font-weight:bold;color:blue',
      environment.version,
      'version',
    );

    const { getLocalUser, getRemoteUsers, handleLogin, moveCursor, cleanup } = this.crdtService.createCrdtState();
    this.moveCursor = moveCursor;
    this.crdtCleanup = cleanup;
    
    this.crdtService.awarenessChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ changes, event }) => {
        if (event === 'local') {
          this.setLocalUser(getLocalUser());
        } else {
          this.setRemoteUsers(getRemoteUsers());
        }
      });
    
    this.userStore.onUserStateChange()
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        this.currentUser = user;
        const userId = `${this.currentUser?.firstName} ${this.currentUser?.lastName}`;
        handleLogin(userId);
      });

    this.editingScenario$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((editingScenario) => {
      this.editingScenario = editingScenario;
    })

    this.editingEvent$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((editingEvent) => {
      this.editingEvent = editingEvent;
    })

    this.editingVersion$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((editingVersion) => {
      this.editingVersion = editingVersion;
    })

    this.selectedWorkspace$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((selectedWorkspace) => {
      this.selectedWorkspace = selectedWorkspace;
    })

    this.editingSitScenarios$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((editingSitScenarios) => {
      this.editingSitScenarios = editingSitScenarios;
    })

    combineLatest(
      this.selectedScenarios$,
      this.sitValidatingScenario$,
      this.sitSimulatingScenario$
    )
    .pipe(takeUntil(this.destroy$))
    .subscribe(([selectedScenarios, sitValidatingScenario, sitSimulatingScenario]) => {
      this.selectedScenarios = selectedScenarios;
      this.sitValidatingScenario = sitValidatingScenario;
      this.sitSimulatingScenario = sitSimulatingScenario;
      this.simulatingScenarioIds = selectedScenarios.filter(scenario => this.isScenarioProcessing(scenario?.id)).map(scenario => scenario?.id);
    })
  }
  
  ngOnDestroy(): void {
    if (this.crdtCleanup) {
      this.crdtCleanup();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
  
  setLocalUser(localUser: any) {
    this.localUser = localUser;
  }

  setRemoteUsers(remoteUsers: any) {
    this.remoteUsers = remoteUsers;
  }

  @HostListener('window:pointermove', ['$event'])
  onPointerMove(event: PointerEvent) {
    const scrollY = window.scrollY;
    const scrollX = window.scrollX;
    const screenHeight = window.innerHeight;
    const screenWidth = window.innerWidth;

    const cursorPosition: Position = {
      top: (event.clientY + scrollY), // / screenHeight,
      left: (event.clientX + scrollX) / screenWidth,
    };

    if (this.moveCursor) {
      const cursorProps = {
        newPosition: cursorPosition,
        workspaceId: this.selectedWorkspace?.id || '',
        editingScenarioId: this.editingScenario?.id || '',
        editingEventId: this.editingEvent?.id || '',
        editingVersionId: this.editingVersion?.id || '',
        editingSitScenarios: this.editingSitScenarios || [],
        simulatingScenarioIds: this.simulatingScenarioIds || [],
      }
      this.moveCursor(cursorProps);
    }
  }

  isScenarioValidatingDRP(scenarioId): boolean {
    return this.sitValidatingScenario == scenarioId;
  }

  isScenarioTriggeringSimulation(scenarioId): boolean {
    return this.sitSimulatingScenario == scenarioId;
  }

  isScenarioSimulatingDRP(scenarioId): boolean {
    const scenario = this.selectedScenarios?.find(s => s.id == scenarioId);

    const hasRunningTask = scenario?.tasksSummary.status === 'inProgress';

    return hasRunningTask;
  }

  isScenarioProcessing(scenarioId): boolean {
    return this.isScenarioValidatingDRP(scenarioId)
      || this.isScenarioTriggeringSimulation(scenarioId)
      || this.isScenarioSimulatingDRP(scenarioId)
  }
}
